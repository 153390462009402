import { render, staticRenderFns } from "./steepLine.vue?vue&type=template&id=a47a3320&scoped=true&"
import script from "./steepLine.vue?vue&type=script&lang=js&"
export * from "./steepLine.vue?vue&type=script&lang=js&"
import style0 from "./steepLine.vue?vue&type=style&index=0&id=a47a3320&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a47a3320",
  null
  
)

export default component.exports