<template>
  <div class="w-full h-full rounded-lg all flex p-5 md:pl-6">
    <div class="flex w-1/3 item cursor-pointer items-center">
        <svg-icon
          name="calendar"
          class="h-12 mr-4 ico"
          original
        />
        <div class="">
         <div class="titre"> Date du sinitre</div>
          <div class="sous">{{ conversion(item.createdAt) }}</div>
        </div>
    </div>

    <div class="flex w-1/3 item cursor-pointer items-center">
      <svg-icon
        name="map"
        class="h-12 mr-4 ico"
        original
      />
      <div class="">
        <div class="titre">Lieu du sinitre</div>
        <div class="sous">{{item.body.place}}</div>
      </div>
    </div>

    <div class="w-1/3 itemFin cursor-pointer items-center">
      <div class="">
        <div class="titre">Circonstances</div>
        <div class="sous">{{item.body.circumstance.substr(0, limite)}} <span v-if="item.body.circumstance.length > limite">...</span> <span class="cursor-pointer" v-if="item.body.circumstance.length > limite" @click="readMore"> Lire tout</span></div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      limite: 20
    }
  },

  methods: {
    conversion (index) {
      return new Date(index).toLocaleDateString()
    },

    readMore () {
      this.limite = this.item.body.circumstance.length
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .titre{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
.sous{
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
}

.all{
 width: 100%;
  height: 84px;
  background: #E8E8E8;
  border-radius: 15px;
  font-family: $font-default;
}
span{
  text-decoration-line: underline;
  color: #015D9A;
}

@media screen and (max-width: 700px){
  .all{
    flex-wrap: wrap;
    height: auto;
  }
  .item{
    width: 50%;
  }
  .itemFin{
    width: 100%;
    margin-top: 30px;
  }
  .ico{
    height: 2rem;
  }
}

@media screen and (max-width: 325px){
  .ico{
    height: 1.5rem;
  }
  .item{
    width: 100%;
    margin-bottom: 20px;
  }
  .itemFin{
    margin-top: 0px;
  }
}
</style>
