<template>
  <div class="w-full h-full">
    <img src="../../../assets/images/png/aai.jpg" alt="" class="w-full h-full" v-if="url === null">
    <img :src="url" alt="" class="w-full h-full" v-if="url !== null">
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },

  props: {
    donne: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      url: null
    }
  },
  created () {
    this.dataURLtoFile(this.donne)
  },

  methods: {

    dataURLtoFile (info) {
      const base64Data = info
      fetch(base64Data)
        .then((response) => {
          let str = base64Data
          if (base64Data.indexOf('data:image/') === -1) {
            str = 'data:image/*;base64,' + base64Data
          }
          fetch(str)
            .then((resp) => {
              resp.blob().then((res) => {
                const file = new File([res], 'File name', { type: 'image/*' })

                this.url = URL.createObjectURL(file)
              })
                .catch(error => {
                  console.log(error)
                })
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
img{
border-radius: 10px;
  object-fit: cover;
}
</style>
