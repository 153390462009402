<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div class="titre">
            Bonjour Cher client. Nous avons reçu votre déclaration. Merci de bien vouloir compléter les pièces ci-après :
            <br>
            <br>
            <div v-for="(info, index) in allDoc" :key="index" class="info">
              - {{info}}
            </div>

            <br>
            <div>{{item.comment}}</div>
          </div>
            <div class="button continuer w-full mt-5" @click="yes">Documents manquants</div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      default: null
    },
    donnes: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      allDoc: []
    }
  },
  created () {
    const manquant = this.item.document
    for (const i in manquant) {
      if (manquant[i] === 1) {
        this.allDoc.push('Images des dégats corporels')
      }
      if (manquant[i] === 2) {
        this.allDoc.push('Images des dégats matériels')
      }
      if (manquant[i] === 3) {
        this.allDoc.push('Procès verbal')
      }
      if (manquant[i] === 4) {
        this.allDoc.push('Devis/factures des réparations')
      }
    }
  },

  methods: {
    fermer () {
      this.$emit('oga', false)
    },
    yes () {
      const obj = {
        data: this.donnes,
        allDoc: this.allDoc
      }
      this.$store.dispatch('saveManquant', obj)
      this.$router.push('/document')
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 40%;
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 27px;
      color: #303030;
    }

    .button{
      background-color: #FFDDDF;
      height: 57.36px;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      color: #FF4B55;
      box-sizing: border-box;
      border-radius: 10px;
      border: none;
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 700px){
      .success-container{
        width: 100%;
        border-radius: 0px;
        height: 100vh;
        padding: 50px 30px !important;
        text-align: center;
      }
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 17px;
      }

      .button{
        width: 100%;
        height: 50px;
        font-size: 17px;
      }
    }

    @media screen and (max-width: 325px){

    }
</style>
