<template>
  <div class="w-full h-full all">
    <div class="md:w-1/3 titleBox">
       <div class="titre"> Images des dégats matériels </div>
      <div class="w-1/5 mt-2">
        <divider height="4px" color="#FFC300"/>
      </div>
    </div>

    <div class="mt-6 flex flex-wrap">
      <div class="imageStyle mb-4 mr-2 ml-1" v-for="(item, index) in allBase" :key="index">
        <imageTemplate class="w-full h-full" :donne="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import divider from '@/components/helper/add/divider'
import imageTemplate from '@/components/helper/add/imageTemplate'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'

export default {
  name: 'index',
  components: {
    divider,
    imageTemplate
  },
  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allDegats: [],
      allBase: []
    }
  },
  mounted () {
    const data = this.item.images

    if (data.imagesOne !== undefined) {
      this.allDegats.push(data.imagesOne)
    }
    if (data.imagesTwo !== undefined) {
      this.allDegats.push(data.imagesTwo)
    }
    if (data.imagesThree !== undefined) {
      this.allDegats.push(data.imagesThree)
    }
    if (data.imagesFour !== undefined) {
      this.allDegats.push(data.imagesFour)
    }

    for (const item in this.allDegats) {
      this.trackImage(this.allDegats[item])
    }
  },

  methods: {
    async trackImage (index) {
      index = index.replace('http://localhost:3000/v2/', apiroutes.baseURL)
      await http.get(index)
        .then(response => {
          const data = response.data.data
          for (const item in data) {
            this.allBase.push(data[item])
          }
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
  }
.sous{
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #909090;
}

.all{
 width: 100%;
  font-family: $font-default;
}
span{
  text-decoration-line: underline;
  color: #015D9A;
}
.imageStyle{
  // border: 1px solid #7735DA;
  box-sizing: border-box;
  border-radius: 18px;
  height: 190px;
  width: 32%;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .imageStyle{
    height: 200px;
    width: 31%;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .titleBox{
    width: 50%;
  }
  .imageStyle{
    height: 170px;
    width: 31%;
  }
}

@media screen and (max-width: 700px){
  .imageStyle{
    height: 90px;
    width: 28%;
  }
}

@media screen and (max-width: 325px){
  .imageStyle{
    height: 70px;

  }
}
</style>
