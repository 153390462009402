<template>
  <div class="about-page w-full h-screen" id="element">
    <popupDocManq :want-to-make-offer="activeDoc" v-if="activeDoc" :item="manquant" :donnes="item" @oga="retourDoc"/>
    <div class="flex items-center" @click="retour" >
        <svg-icon
          name="back"
          class="h-16 mr-5 ico"
          original
        />
      <div class="titre">Détails</div>
    </div>

    <div>
      <div class="flex mt-6 justify-center cursor-pointer">
        <div class="md:w-1/5 item" @click="openDoc(0)">
          <steep-line :all-steep="allSteep" :position="position" :index="0" :error="error" :label="allSteep[0]" :color="steepColor"/>
        </div>
        <div class="md:w-1/5 item" @click="openDoc(1)">
          <steep-line :all-steep="allSteep" :position="position" :index="1" :error="error" :label="allSteep[1]" :color="steepColor"/>
        </div>
        <div class="md:w-1/5 item" @click="openDoc(2)">
          <steep-line :all-steep="allSteep" :position="position" :index="2" :error="error" :label="allSteep[2]" :color="steepColor"/>
        </div>
        <div class="md:w-1/7 itemSpec" @click="openDoc(3)">
          <steep-line :all-steep="allSteep" :position="position" :index="3" :error="error" :label="allSteep[3]" :color="steepColor"/>
        </div>
      </div>
    </div>

    <div class="baniere md:flex items-center p-6 mt-6">
      <div class="md:w-2/6 flex">
        <div class="nom w-full cursor-pointer" @click="retour">
          <div class="enonce">
            Police <span class="police">{{item.body.police}}</span>
          </div>

          <div class="flex mt-1 items-center product" v-if="item.body.subscriptionData.product.categorie === 'AUTO' || item.body.subscriptionData.product.categorie === 'Automobile'">
            <svg-icon
              name="auto-white"
              class="h-4 mr-2"
              original
            />
            <div class="type">Assurance Automobile</div>
          </div>

          <div class="flex mt-1 items-center product" v-if="item.body.subscriptionData.product.categorie === 'MOTO' || item.body.subscriptionData.product.categorie === 'Moto'">
            <img src="../../../assets/icons/moto.svg" alt="" class="h-4 w-4 mr-2">
            <div class="type">Assurance Moto</div>
          </div>

          <div class="flex mt-1 items-center product" v-if="item.body.subscriptionData.product.categorie === 'VOYAGE' || item.body.subscriptionData.product.categorie === 'Voyage'">
            <img src="../../../assets/icons/voyage.svg" alt="" class="h-4 w-4 mr-2">
            <div class="type">Assurance Voyage</div>
          </div>

          <div class="flex mt-1 items-center product" v-if="item.body.subscriptionData.product.categorie === 'MRH' || item.body.subscriptionData.product.categorie === 'Habitation'">
            <img src="../../../assets/icons/mrh.svg" alt="" class="h-4 w-4 mr-2">
            <div class="type">Assurance Mrh</div>
          </div>

          <div class="flex mt-1 resultat">
            <div class="active-bout bout flex items-center justify-center pl-4 pr-4"  v-if="item.status">
              <div>Active</div>
            </div>

            <div class="inactive-bout bout flex items-center justify-center pl-4 pr-4"  v-if="!item.status">
              <div>Inactive</div>
            </div>
          </div>
        </div>
      </div>

      <div class="md:w-2/6 montant">
        <div>Montant demandé</div>
        <div class="md:mt-2 amount">
          <span v-if="item.amount === null">0</span>
          <span v-if="item.amount !== null">{{item.amount.toLocaleString()}}</span>
          Fr
        </div>
      </div>

      <div class="flex justify-end md:w-2/6">
        <div class="button flex pl-5" @click="goTo('proposition')" :class="{'diseable': item.amount === null}">
          <div class="mr-2">
            Proposition financière
          </div>
          <svg-icon
            name="chevron-right"
            class="h-6"
            original
          />
        </div>
      </div>
    </div>

    <div class="flex justify-end mt-4">
      <div class="telecharge flex items-center pl-4 pr-4 cursor-pointer" @click="openAttestation" v-if="item.atestation">
        <div>Téléchargez votre attestation de declaration</div>
      </div>
    </div>

    <div class="mt-4 bandeZone">
      <bande :item="item"/>
    </div>

    <div class="mt-6">
      <degatsMateriels :item="item"/>
    </div>
  </div>
</template>

<script>
import steepLine from '@/components/cedro/sinistre/steepLine'
import bande from '@/components/cedro/sinistre/bande'
import degatsMateriels from '@/components/cedro/sinistre/degatsMateriels'
import popupDocManq from '@/components/popup/popupDocManq'

export default {
  name: 'index',
  components: {
    steepLine,
    bande,
    degatsMateriels,
    popupDocManq
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      allSteep: ['Déclaration', 'Ouverture', 'Instruction', 'Paiement'],
      activeDoc: false,
      position: 1,
      error: -1,
      manquant: null
    }
  },

  created () {
    if (this.item.amount !== null) {
      this.$store.dispatch('saveSinistre', this.item)
    }
  },

  mounted () {
    this.smooth()
    if (this.item.status === 'DECLARED') {
      this.position = 1
      if (this.item.body.manquant.declared !== undefined) {
        this.error = this.position - 1
        this.manquant = this.item.body.manquant.declared
      }
    }
    if (this.item.status === 'PENDING') {
      this.position = 2
      if (this.item.body.manquant.pending !== undefined) {
        this.error = this.position - 1
        this.manquant = this.item.body.manquant.pending
      }
    }
    if (this.item.status === 'DONE' && this.item.paymentStatus !== 'PAID') {
      this.position = 3
      if (this.item.body.manquant.done !== undefined) {
        this.error = this.position - 1
        this.manquant = this.item.body.manquant.done
      }
    }
    if (this.item.status === 'PAID' || this.item.paymentStatus === 'PAID') {
      this.position = 4
      if (this.item.body.manquant.paid !== undefined) {
        this.error = this.position - 1
        this.manquant = this.item.body.manquant.paid
      }
    }
  },

  methods: {
    goTo (index) {
      if (this.item.amount !== null && this.item.paymentStatus !== 'PAID') {
        this.$router.push('/' + index)
      }
    },

    openAttestation () {
      window.open(this.item.atestation, '_blank')
    },

    retourDoc (answer) {
      this.activeDoc = answer
    },

    openDoc (index) {
      if (this.error === index) {
        this.activeDoc = true
      }
    },

    retour () {
      this.$emit('oga', false)
    },

    smooth () {
      const element = document.getElementById('element')
      // element.scrollIntoView(true)
      element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.baniere{
  width: 100%;
  border-radius: 20px;
  height: 159px;
  background: #0269AD;
  color: #FFFFFF;
}

.bout{
  height: 19px;
  background: #FFFFFF;
  border-radius: 12.1558px;
  font-weight: 500;
  font-size: 10px;
  line-height: 17px;
  color: #00BF53;
}

.inactive-bout{
  color: #d95e69;
}

.telecharge{
  background: #0269AD;
  border: 1px solid #0269AD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 17px;
  line-height: 153%;
  color: #FFFFFF;
  height: 50px;
}

.resultat{
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  font-family: $popins;
}
.amount{
  font-family: $rubik;
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 43px;
  color: #FFFFFF;
}
.enonce{
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  font-family: $font-default;
  color: #fff;
}
.reponse{
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
}

.about-page{
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
}
  .titre{
    font-weight: 500;
    font-size: 36px;
    line-height: 43px;
    color: #000000;
    font-family: $rubik
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .button{
    height: 71px;
    background: #FFFFFF;
    border-radius: 20px;
    font-weight: 500;
    font-size: 18px;
    line-height: 144%;
    letter-spacing: 0.04em;
    color: #000000;
    font-family: $font-default;
    border: none;
  }

  .svg-icon{
    fill: white;
  }

@media screen and (max-width: 1024px) and (min-width: 770px){
  .ico{
    height: 3rem;
  }
  .item{
    width: 40% !important;
  }
  .itemSpec{
    width: 28% !important;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .ico{
    height: 3rem;
  }
  //.baniere{
  //  display: block;
  //  padding: 30px !important;
  //  height: auto;
  //}
  .button{
    height: 50px;
    font-size: 12px;
  }
  .item{
    width: 40% !important;
  }
  .itemSpec{
    width: 27% !important;
  }
  .steepColor{
    font-size: 16px;
    width: 25%;
    text-align: center;
  }
}

@media screen and (max-width: 700px){
  .about-page{
    width: 100%;
  }
  .ico{
    height: 2.4rem;
  }
  .titre{
    font-size: 25px;
  }
  .item{
    width: 39% !important;
  }
  .itemSpec{
    width: 23% !important;
  }
  .baniere{
    height: auto;
    border-radius: 20px;
    padding: 20px !important;
    margin-top: 20px !important;
    .enonce{
      font-size: 14.8px;
    }
    .reponse{
      font-size: 18.2px;
    }
  }
  .button{
    height: 50px;
    font-size: 14px;
    margin-top: 20px !important;
    width: 100%;
  }
  .montant{
    display: flex;
    align-items: center;

  }
  .amount{
    margin-left: 30px;
    font-size: 21px;
  }
  .resultat{
    display: none;
  }
  .police{
    font-weight: bold;
    margin-left: 10px;
  }
  .product{
    margin-top: 10px !important;
  }
  .bandeZone{
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 325px){
  .titre{
    font-size: 22px;
  }
  .active-bout{
    display: none;
  }
  .baniere{
    height: auto;
    border-radius: 20px;
    padding: 20px !important;
    margin-top: 20px !important;
    .enonce{
      font-size: 12.8px;
    }
    .reponse{
      font-size: 14.2px;
    }
  }
  .button{
    height: 50px;
    font-size: 14px;
    margin-top: 20px !important;
    width: 100%;
  }
  .item{
    width: 25% !important;
  }
  .itemSpec{
    width: 23% !important;
  }
}
</style>
