<template>
  <div class="w-full h-full ">
    <div class="w-full flex pl-4">
      <div class="md:w-1/2 md:mr-5 premier">
        <div class="flex justify-center">
          <svg-icon
            name="valid"
            class="h-12 ico"
            original
            v-if="position > index && error !== index"
          />
          <svg-icon
            name="invalid"
            class="h-12 ico"
            original
            v-if="position <= index &&  error !== index"
          />
          <svg-icon
            name="alerte"
            class="h-12 ico"
            original
            v-if="error === index"
          />
        </div>

        <div class="steepColor mt-5">{{label}}</div>
      </div>

      <div class="w-1/2 mr-3 diviser mt-5" v-if="allSteep.length - 1 > index">
        <divider height="1px" color="#DDDDDD"/>
      </div>
    </div>
  </div>
</template>

<script>
import divider from '@/components/helper/add/divider'

export default {
  name: 'index',
  components: {
    divider
  },

  props: {
    allSteep: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: null
    },
    position: {
      type: Number,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {

    }
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.svg-fill{
  fill: #fff;
}
.steepColor{
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: #000000;
  font-family: $font-default;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .steepColor{
    font-size: 18px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .diviser{
    width: 60%;
  }
  .steepColor{
    font-size: 16px;
  }
}

@media screen and (max-width: 700px){
  .ico{
    height: 3rem !important;
    width: 70% !important;
    margin-right: 10px !important;
  }
  .diviser{
    width: 40%;
  }
  .pl-4{
    padding-left: 0px !important;
  }
  .steepColor{
    font-size: 12px;
  }
}

@media screen and (max-width: 325px){

}
</style>
